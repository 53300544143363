import React, { useEffect, useRef, useState } from "react"
import capitalize from "lodash/capitalize"
import isEmpty from "lodash/isEmpty"
import moment from "moment"
import uniq from "lodash/uniq"
import { Badge, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import Radio from "../common/forms/radio"
import { QueryName } from "../../hasura/queryNames"
import { Role } from "../../interfaces/role"
import { UserState, fetchUserEventsAction, userSelector } from "../../hasura/slices/user"

const COLORS = [
  "#0074D9",
  "#FF4136",
  "#2ECC40",
  "#FF851B",
  "#FFDC00",
  "#001f3f",
  "#39CCCC",
  "#7FDBFF",
  "#01FF70",
  "#F012BE",
  "#B10DC9",
  "#FF3860",
  "#3D9970",
  "#2ECC71",
  "#00B0FF",
  "#FF851B",
  "#FFDC00",
  "#0088CE",
  "#E81111",
  "#4E82D0",
  "#E58022",
  "#EF3340",
  "#F57500",
  "#FFC300",
  "#007F3D",
  "#73B500",
  "#00A2FF",
  "#D4C374",
  "#FF5D20",
  "#FFD1A3",
  "#4CC8C3",
  "#10C135",
  "#45E61F",
  "#00B0D6",
  "#FFAE4B",
  "#FFD700",
  "#5DBF00",
  "#FFE500",
  "#0092DD",
  "#FF6300",
]

export default function Activity() {
  const dispatch = useDispatch()

  const { accessToken, userEvents, isQuerying }: UserState = useSelector(userSelector)

  const [colorsHash, setColorsHash] = useState<any>({})
  const [role, setRole] = useState<Role>(Role.Teacher)

  const fetchUserEventsInterval = useRef<any | null>(null)

  const fetchData = () => {
    if (!accessToken) return

    dispatch(fetchUserEventsAction(accessToken, role))
  }

  useEffect(() => {
    if (!accessToken) return

    clearInterval(fetchUserEventsInterval.current)
    fetchUserEventsInterval.current = setInterval(fetchData, 15 * 1000)
    fetchData()

    return () => {
      clearInterval(fetchUserEventsInterval.current)
    }
  }, [accessToken, role])

  useEffect(() => {
    if (!userEvents?.length) return

    const organizationEventTypeIds = uniq(userEvents.map((event) => event.user_event_type_id)).sort()
    const colorsHash: any = {}
    organizationEventTypeIds.forEach((id, idx) => (colorsHash[id] = COLORS[idx]))
    setColorsHash(colorsHash)
  }, [userEvents])

  const isTeacher = role === Role.Teacher

  return (
    <div className="w-100">
      <div className="mb-2 d-flex align-items-start justify-content-between">
        <div>
          <div className="d-flex align-items-end">
            <h4 className="bold m-0">Feed</h4>

            <div className="ml-5 d-flex justify-content-center">
              <Radio onClick={() => setRole(Role.Student)} checked={role === Role.Student} label="Student" />
              <Radio onClick={() => setRole(Role.Teacher)} checked={role === Role.Teacher} label="Teacher" />
            </div>
          </div>

          <div className="d-flex">
            <p className="text-xs text--gray5 font-italic">Displaying last 1,000 events</p>
            <Spinner className="transition-m ml-2" style={{ opacity: isQuerying[QueryName.FetchUserEvents] ? 1 : 0 }} size="sm" />
          </div>
        </div>
      </div>

      {userEvents?.length && (
        <div>
          {userEvents.map((event, idx) => (
            <div key={idx} className="d-flex gap-20px text-xs">
              <p className="m-0 width-150px single-line">{moment(event.created_at).format("h:mma MMM Do")}</p>

              <div className="m-0 width-400px single-line d-flex align-items-center">
                {event.user.has_premium || event.user.classroom?.teacher.has_premium ? (
                  <Badge className="mr-2 bg--purple text--white">Premium</Badge>
                ) : null}

                <p className="m-0">
                  {event.user.display_name}
                  <span className="text--gray7">{isTeacher ? ` (${event.user.email.toLowerCase()})` : null}</span>
                </p>
              </div>

              {role === Role.Student && (
                <p className="m-0 width-100px single-line">{event.user.stars.toLocaleString("en", { useGrouping: true })} stars</p>
              )}

              <p className="m-0 width-150px single-line">{event.user.school || event.user.classroom?.teacher?.school}</p>

              <p className="m-0 width-150px single-line">created {moment(event.user.created_at).fromNow()}</p>

              <p style={{ color: colorsHash[event.user_event_type_id] }} className="m-0 bold width-150px">
                {capitalize(event.user_event_type.display_name).replace(" ai ", " AI ").replace("pdf", "PDF").replace(" stat ", " STAT ")}
              </p>

              <p className="m-0 width-150px">
                {!isEmpty(event.additional_data) && event.additional_data !== "{}" && JSON.stringify(event.additional_data)}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
