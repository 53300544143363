import React, { useEffect, useMemo } from "react"
import moment from "moment"
import sum from "lodash/sum"
import { Badge, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useTable, useSortBy } from "react-table"

import Radio from "../common/forms/radio"
import { NarrowCell, Header, HeaderCell, Table, XSmallTextCell, isColumn } from "../common/tables/table"
import { QueryName } from "../../hasura/queryNames"
import { RecentAndPremiumUsers_users } from "../../hasura/queries/types/RecentAndPremiumUsers"
import { UserState, fetchRecentAndPremiumUsersAction, userSelector } from "../../hasura/slices/user"
import { pluralize } from "../../lib/helpers"

const defaultColumn: any = {
  Cell: XSmallTextCell,
}

export default function UsersTab() {
  const dispatch = useDispatch()

  const { accessToken, recentAndPremiumUsers, isQuerying }: UserState = useSelector(userSelector)

  const [view, setView] = React.useState("all")

  useEffect(() => {
    if (!accessToken) return

    dispatch(fetchRecentAndPremiumUsersAction(accessToken))
  }, [accessToken])

  const columns = useMemo(
    () => [
      {
        Header: "Created",
        accessor: (u: RecentAndPremiumUsers_users) => moment(u.created_at).fromNow(),
        sortDescFirst: true,
      },
      {
        Header: "Name",
        accessor: (u: RecentAndPremiumUsers_users) => `${u.display_name} ${u.email}`,
        sortDescFirst: false,
      },
      {
        Header: "School",
        accessor: "school",
        sortDescFirst: false,
      },
      {
        Header: "Subrole",
        accessor: "subrole",
        sortDescFirst: true,
      },
      {
        Header: "Grades",
        accessor: "grades",
        sortDescFirst: true,
      },
      {
        Header: "Premium",
        accessor: (u: RecentAndPremiumUsers_users) => u.has_premium,
        Cell: (user: { value: boolean }) => (
          <div className="text-s m-0 position-relative">{user.value ? <Badge className="bg--purple text--white">Premium</Badge> : null}</div>
        ),
      },
      {
        Header: "# Classes",
        accessor: (u: RecentAndPremiumUsers_users) => u.classrooms.length,
        sortDescFirst: true,
      },
      {
        Header: "# Students",
        accessor: (u: RecentAndPremiumUsers_users) => sum(u.classrooms.map((c) => c.students_aggregate.aggregate?.count)),
        sortDescFirst: true,
      },
      {
        Header: "# Active Students",
        accessor: (u: RecentAndPremiumUsers_users) => sum(u.classrooms.map((c) => c.students_with_progress.aggregate?.count)),
        sortDescFirst: true,
      },
      {
        Header: "# Games (30 days)",
        accessor: (u: RecentAndPremiumUsers_users) => u.games_aggregate.aggregate?.count,
        sortDescFirst: true,
      },
      {
        Header: "# Assignments (30 days)",
        accessor: (u: RecentAndPremiumUsers_users) => sum(u.classrooms.map((c) => c.assignments_aggregate.aggregate?.count)),
        sortDescFirst: true,
      },
    ],
    []
  )

  const [filteredUsers, setFilteredUsers] = React.useState<RecentAndPremiumUsers_users[]>([])

  useEffect(() => {
    const filteredUsers = recentAndPremiumUsers.filter(
      (u) => view === "all" || (view === "new" ? moment(u.created_at).isAfter(moment().subtract(1, "month")) : u.has_premium)
    )

    setFilteredUsers(filteredUsers)
  }, [recentAndPremiumUsers, view])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      // @ts-ignore
      columns,
      defaultColumn,
      data: filteredUsers,
    },
    useSortBy
  )

  const colSpan = (data: any) => (isColumn(data, "School") || isColumn(data, "Name") ? 2 : 1)

  return (
    <div className="w-100">
      <div className="mb-2 d-flex align-items-start justify-content-between">
        <div>
          <div className="d-flex align-items-end">
            <h4 className="bold m-0">Users</h4>

            <p className="mb-0 ml-3">{pluralize(`${view === "all" ? "" : `${view} `}user`, filteredUsers.length)}</p>
          </div>

          <div className="d-flex">
            <p className="bold mb-0 text--gray7">Sort</p>

            <div className="ml-3">
              <div>
                <Radio onClick={() => setView("all")} checked={view === "all"} label="All" />
                <Radio onClick={() => setView("new")} checked={view === "new"} label="New" />
                <Radio onClick={() => setView("premium")} checked={view === "premium"} label="Premium" />
              </div>
            </div>
          </div>

          {isQuerying[QueryName.FetchRecentAndPremiumUsers] ? (
            <Spinner className="mt-2" color="primary" />
          ) : (
            <Table style={{ maxWidth: "1300px" }} className="w-100" cellSpacing={0} {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any, idx: number) => (
                      <HeaderCell noBorder key={idx} {...column.getHeaderProps(column.getSortByToggleProps({}))} colSpan={colSpan(column)}>
                        {column.Header && <Header>{column.render("Header")}</Header>}
                      </HeaderCell>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row: any) => {
                  prepareRow(row)

                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell: any, idx: number) => {
                        return (
                          <NarrowCell key={idx} {...cell.getCellProps()} colSpan={colSpan(cell.column)}>
                            {cell.render("Cell")}
                          </NarrowCell>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </div>
  )
}
