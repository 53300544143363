import React, { useState } from "react"
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"

import Activity from "../components/users/activity"
import Layout from "../components/layout"
import UsersTab from "../components/users/index"

export default function Users() {
  const [activeTab, setActiveTab] = useState("users")

  return (
    <Layout>
      <div className="max-width-1500px default-width-padding mx-auto mt-3 pb-5">
        <Nav tabs className="mb-3">
          <NavItem>
            <NavLink className={`pointer ${activeTab === "users" ? "active" : ""}`} onClick={() => setActiveTab("users")}>
              Users
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={`pointer ${activeTab === "activity" ? "active" : ""}`} onClick={() => setActiveTab("activity")}>
              Feed
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="activity">
            <Activity />
          </TabPane>

          <TabPane tabId="users">
            <UsersTab />
          </TabPane>
        </TabContent>
      </div>
    </Layout>
  )
}
